import {Group, Text} from "@mantine/core";
import {NavLink as RouterLink} from "react-router-dom";
import {EgwLogo} from "../../components/EgwLogo";
import React from "react";

export const EgwTitle = () => {
    return <Group gap={5}>
        <Text c={'black'} size={"xl"} component={RouterLink} to={'/'}>Chat</Text>
        <RouterLink to={'/'}><EgwLogo/></RouterLink>
    </Group>
}
