import React, {useMemo} from 'react';
import {Group, SegmentedControl, SegmentedControlProps} from '@mantine/core';
import {SearchType} from '../util/config';
import {SegmentedControlItem} from "@mantine/core/lib/components/SegmentedControl/SegmentedControl";
import {SearchTypeIcon} from "./SearchTypeIcon";


const getSearchTypeLabel = (type: SearchType) => {
    switch (type) {
        case SearchType.Auto:
            return 'Auto';
        case SearchType.Keyword:
            return 'Keyword search';
        case SearchType.Semantic:
            return 'Q&A Search';
    }
}

const getOptionList = () => window.CHATEGW_SETTINGS.chatEnabled
    ? [SearchType.Auto, SearchType.Semantic, SearchType.Keyword]
    : [SearchType.Semantic, SearchType.Keyword];

const getOptions = (): SegmentedControlItem[] => getOptionList()
    .map(r => ({
        label: <Group gap={5} wrap={"nowrap"} key={r} justify={"center"}>
            <SearchTypeIcon type={r} size={16}/>
            <span>{getSearchTypeLabel(r)}</span>
        </Group>, value: r
    }));


export const SearchTypeSelector = ({value, onChange, ...props}: {
    value: SearchType,
    onChange?: (val: SearchType) => void
} | SegmentedControlProps) => {
    const controlOptions = useMemo(getOptions, []);
    return <SegmentedControl {...props}
                             data={controlOptions} value={value}
                             onChange={e => onChange && onChange(e as SearchType)}/>;
};

