import React, {ReactNode} from "react";
import {Text} from "@mantine/core";
import {ChatMessageLinkHandlerType} from "./ChatMessageLink";
import {ChatMessageLinkSet} from "./ChatMessageLinkSet";

const linkExtractor: RegExp = /\(\s*(\d{1,3}(\s*[,-]\s*\d{1,3})*)\s*(,|\)|$)/ig;


export const ChatMessageText = ({text, onClick}: { text: string, onClick?: ChatMessageLinkHandlerType }) => {
    let match;
    const output: ReactNode[] = [];
    const matches: RegExpExecArray[] = [];
    while (!!(match = linkExtractor.exec(text))) {
        matches.push(match);
    }
    let prevPosition = 0;
    for (let match of matches) {
        output.push(<span key={"t" + match.index}>{text.slice(prevPosition, match.index)}</span>);
        output.push(<ChatMessageLinkSet key={"l" + match.index}
                                        text={text.slice(match.index, match.index + match[0].length)}
                                        onClick={onClick}
        />);
        prevPosition = match.index + match[0].length;
    }
    output.push(<span key={"end"}>{text.slice(prevPosition)}</span>)
    return <Text size={"sm"}>{output}</Text>
}
