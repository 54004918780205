import {
    ChatMessageDtoFragment,
    ChatMessageDtoFragmentDoc,
    SemanticHistoryEntryDtoFragment,
    SemanticSearchResultDtoFragment,
    SemanticSearchResultDtoFragmentDoc
} from "../../_gql/graphql";
import {ActionIcon, Box, Container, Divider, Group, Paper, Title, useMantineTheme} from "@mantine/core";
import {useMediaQuery, useToggle} from "@mantine/hooks";
import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {unmaskFragment} from "../../_gql";
import {SemanticSearchContextProvider, useSemanticSearchContext} from "./SemanticSearchContext";
import {SemanticSearchSnippet} from "./SemanticHistoryPage/SemanticSearchSnippet";
import {ChatMessageList} from "./ChatSectionView/ChatMessageList";
import {ChatInput} from "./SemanticHistoryPage/ChatInput";
import {SemanticSectionView} from "./SemanticHistoryPage/SemanticSectionView";
import classes from "./SemanticHistoryPage.module.scss"
import {IconListSearch, IconMessageChatbot} from "@tabler/icons-react";

const useWideScreenDetection = () => {
    const theme = useMantineTheme();
    return useMediaQuery(`(min-width: ${theme.breakpoints.lg} )`) ?? false;
}
const SearchResultListContext = createContext({
    index: 0
});
export const useSearchResultListContext = () => useContext(SearchResultListContext);

const SearchResultList = ({activeIndex}: {
    activeIndex: number
    onOpenChat?: () => void
}) => {
    const context = useSemanticSearchContext();
    const listContext = useMemo(() => ({
        index: activeIndex
    }), [activeIndex]);
    return <SearchResultListContext.Provider value={listContext}>
        <Box>
            {context.snippets
                .map(sr => <SemanticSearchSnippet
                    snippet={sr} key={context.queryId + "::" + sr.order}/>)}
        </Box>
    </SearchResultListContext.Provider>
};


const SemanticSearchWideView = () => {
    const handleOpenChat = useMemo(() => () => {
    }, []);
    const [active, setActive] = useState(-1)

    function handleLinkClick(snippet: SemanticSearchResultDtoFragment) {
        setActive(snippet.order)

    }

    return (
        <Paper p={'sm'} className={classes.wideView}>
            <SemanticSectionView
                header={<Title order={6}>Chat</Title>}
                footer={<ChatInput px={"25%"}/>}
            >
                <ChatMessageList onLinkClick={handleLinkClick}/>
            </SemanticSectionView>
            <Divider orientation={"vertical"} mx={'md'}/>
            <SemanticSectionView
                header={<Title order={6}>Search results</Title>}
            >
                <SearchResultList activeIndex={active} onOpenChat={handleOpenChat}/>
            </SemanticSectionView>
        </Paper>
    )
}


const NarrowHeaderGroup = ({action, children}: { action?: React.ReactElement, children: React.ReactElement }) => {
    return (<Group justify={"space-between"}>
        {children}
        {action}
    </Group>)
}

const SemanticSearchNarrowView = () => {
    const [isChat, toggleChat] = useToggle();
    const onToggle = () => {
        toggleChat();
    }
    const context = useSemanticSearchContext();
    return (<Container>
        <Paper p={'sm'} className={classes.narrowView}>
            {isChat
                ? <>
                    <SemanticSectionView header={
                        <NarrowHeaderGroup
                            action={<ActionIcon variant={"subtle"} onClick={onToggle}><IconListSearch/></ActionIcon>}>
                            <Title order={5}>Chat with AI Assistant</Title>
                        </NarrowHeaderGroup>}
                                         footer={<ChatInput/>}
                    >
                        <ChatMessageList/>
                    </SemanticSectionView>

                    {/*<ChatInput px={"25%"}/>*/}
                </>
                : <SemanticSectionView header={<NarrowHeaderGroup
                    action={<ActionIcon variant={"subtle"} onClick={onToggle}><IconMessageChatbot/></ActionIcon>}>
                    <Title order={5}> Search results for: {context.query}</Title>
                </NarrowHeaderGroup>}>
                    <SearchResultList activeIndex={-1}/>
                </SemanticSectionView>
            }
        </Paper>
    </Container>);

}
const SemanticHistoryPageInternal = () => {
    const {wide} = useSemanticSearchContext();
    if (wide) {
        return (
            <SemanticSearchWideView/>
        );
    } else {
        return <SemanticSearchNarrowView/>
    }
}

export const SemanticHistoryPage = ({fragment}: { fragment: SemanticHistoryEntryDtoFragment }) => {
    const isWide = useWideScreenDetection();
    const snippets = unmaskFragment(SemanticSearchResultDtoFragmentDoc, fragment.searchResults);
    const messages = unmaskFragment(ChatMessageDtoFragmentDoc, fragment.chat);
    const [msgList, setMsgList] = useState(messages as ChatMessageDtoFragment[]);
    const context = useMemo(() => ({
        query: fragment.query,
        queryId: fragment.id,
        snippets,
        messages: msgList,
        setMessages: setMsgList,
        wide: isWide
    }), [fragment.query, fragment.id, snippets, msgList, isWide, setMsgList]);
    useEffect(() => {
        setMsgList(messages as ChatMessageDtoFragment[])
    }, [messages, setMsgList]);
    return <SemanticSearchContextProvider value={context}>
        <SemanticHistoryPageInternal/>
    </SemanticSearchContextProvider>
}