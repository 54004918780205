import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import './App.css';

import {createTheme, MantineProvider} from '@mantine/core';
import {Notifications} from '@mantine/notifications';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'urql';
import {NotAuthorizedPage} from './error-pages/NotAuthorizedPage';
import {ApplicationRoutes} from './pages/_ApplicationRoutes';
import {useEgwClient} from "./hooks/useEgwClient";
import {AuthProvider} from "react-oidc-context";
import {oidcConfig} from "./util/oidc";
import {BurgerProvider} from "./components/GlobalBurger";
import {NavigationProgress} from '@mantine/nprogress';

const theme = createTheme({
    primaryColor: 'egw',
    colors: {
        'egw': [
            "#edf8fc",
            "#dbeef5",
            "#b3dced",
            "#89cae5",
            "#68badd",
            "#56b1d9",
            "#4aacd8",
            "#3c97c0",
            "#2f86ac",
            "#167498"
        ]
    }
    /** Put your mantine theme override here */
});

export function App() {
    const [auth, client] = useEgwClient();
    return (
        <MantineProvider theme={theme} withCssVariables>
            <Notifications/>
            <NavigationProgress/>
            <BrowserRouter>
                <Provider value={client}>
                    <BurgerProvider>
                        {auth?.isAuthenticated
                            ? <ApplicationRoutes/>
                            : <NotAuthorizedPage/>
                        }
                    </BurgerProvider>
                </Provider>
            </BrowserRouter>
        </MantineProvider>
    );
}


export function SecuredApp() {
    return (<AuthProvider {...oidcConfig}>
        <App/>
    </AuthProvider>)
}