import {ActionIcon, Group, Text} from "@mantine/core"
import {IconThumbDown, IconThumbUp} from "@tabler/icons-react"
import {RatingDtoFragment, VoteTypeEnum} from "../_gql/graphql"

const VoteAction = ({vote, disabled, value, onClick}: {
    vote?: VoteTypeEnum,
    disabled?: boolean,
    value: VoteTypeEnum,
    onClick: (vote: VoteTypeEnum) => void
}) => {
    const handleClick = () => {
        onClick(value);
    }
    const isSelected = vote === value;
    const color = value === VoteTypeEnum.Downvote ? "red" : "green";
    return <ActionIcon size={"xs"}
                       disabled={disabled}
                       variant={isSelected ? "light" : "subtle"}
                       color={isSelected ? color : "gray"}
                       onClick={handleClick}
    >
        {value === VoteTypeEnum.Downvote ? <IconThumbDown/> : <IconThumbUp/>}
    </ActionIcon>
}

export const RatingView = ({rating, disabled, onVote}: {
    rating?: RatingDtoFragment,
    disabled?: boolean,
    onVote: (vote: VoteTypeEnum | null) => void
}) => {
    const handleVoteUp = () => {
        if (rating?.vote === VoteTypeEnum.Upvote) {
            return;
        }
        onVote(rating?.vote === VoteTypeEnum.Downvote ? null : VoteTypeEnum.Upvote);
    }
    const handleVoteDown = () => {
        if (rating?.vote === VoteTypeEnum.Downvote) {
            return;
        }
        onVote(rating?.vote === VoteTypeEnum.Upvote ? null : VoteTypeEnum.Downvote);
    }

    const ratingValue = rating?.rating ?? 0;
    return <Group gap={5} wrap={"nowrap"}>
        <VoteAction disabled={disabled} value={VoteTypeEnum.Downvote} vote={rating?.vote ?? undefined}
                    onClick={handleVoteDown}/>
        <Text size={"xs"} c={ratingValue < 0 ? "red" : ratingValue > 0 ? "green" : undefined}>{ratingValue}</Text>
        <VoteAction disabled={disabled} value={VoteTypeEnum.Upvote} vote={rating?.vote ?? undefined}
                    onClick={handleVoteUp}/>
    </Group>
}
