import {AppShell} from "@mantine/core";
import React from "react";
import {useBurgerContext} from "../components/GlobalBurger";
import {LayoutHeader} from "./Layout/LayoutHeader";
import {LayoutNavBar} from "./Layout/LayoutNavBar";
import styles from "./Layout.module.scss";

export const Layout = ({
                           headerHeight = 36,
                           children
                       }: { children?: React.ReactNode | React.ReactNode[], headerHeight?: number }) => {
    const {opened} = useBurgerContext();
    return <AppShell
        layout={"alt"}
        header={{height: headerHeight}}
        navbar={{width: 300, breakpoint: 'sm', collapsed: {mobile: !opened}}}
        padding={{'base': '2px', 'md': 'md'}}
    >
        <AppShell.Header>
            <LayoutHeader/>
        </AppShell.Header>
        <AppShell.Navbar p={0}>
            <LayoutNavBar/>
        </AppShell.Navbar>
        <AppShell.Main className={styles.appRoot}>
            {children}
        </AppShell.Main>
    </AppShell>
}