import {NavLink as RouterLink, useLocation} from "react-router-dom";
import {ActionIcon, Group} from "@mantine/core";
import {GlobalBurger} from "../../components/GlobalBurger";
import {IconEdit} from "@tabler/icons-react";
import React from "react";
import {EgwTitle} from "./EgwTitle";

export const LayoutHeader = () => {
    const nav = useLocation();
    const path = nav.pathname;
    return <>
        <Group justify={"space-between"} align={"center"} mx={'md'} visibleFrom={"sm"}>
            <EgwTitle/>
        </Group>
        <Group justify={"space-between"} align={"center"} mx={'xs'} hiddenFrom={"sm"}>
            <GlobalBurger size={"sm"}/>
            <EgwTitle/>
            {path !== '/'
                ? <ActionIcon size={'sm'} variant={'subtle'} component={RouterLink} to={'/'}><IconEdit/></ActionIcon>
                : <span>&nbsp;</span>}
        </Group>
    </>;
}