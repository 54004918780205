import {getThemeColor, MantineColor, MantineSize, rem, useMantineTheme} from "@mantine/core";

export const EgwLogo = ({fill = "egw", size = 'md'}: { fill?: MantineColor, size?: MantineSize | number }) => {
    const theme = useMantineTheme();
    const colorValue = getThemeColor(fill, theme);
    const realSize = typeof size === "number" ? size : theme.fontSizes[size];
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 55"
                fill={colorValue} style={{verticalAlign: "middle", height: rem(realSize)}}>
        <g>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M52.007,22.968c0,0,0.105,1.404-1.01,0.672   c-1.115-0.732-3.283-2.775-2.829-8.288c0.455-5.514,4.943-14.535,8.823-15.227c3.88-0.693,6.19,1.476,6.475,7.74   c0,0,0.152,3.255,0.332,3.669c0.18,0.415,0.328,0.363,0.622,0.087c0.295-0.277,1.714-1.757,3.22-5.019   c1.506-3.262,3.116-6.004,3.609-3.07c0,0,0.234,2.846-0.805,11.881c0,0-0.555,6.609-0.446,8.336   c0.109,1.727,0.964-0.231,1.387-1.018c0.424-0.788,4.933-12.352,11.755-7.906c0,0,3.196,2.856,2.827,6.231   c0,0-0.256,1.297-2.277-0.223c-2.02-1.521-8.628-1.28-14.43,15.581c0,0-5.406,16.4-6.583,17.954   c-1.178,1.555-1.589-0.649-1.62-1.366c-0.031-0.717,0.34-5.799,1.481-11.501c1.141-5.702,2.825-19.734,2.626-21.571   c-0.199-1.836-1.485,0.892-1.701,1.324c-0.216,0.433-3.132,5.374-6.4,17.649c-3.268,12.276-6.136,14.99-7.197,15.55   c-1.061,0.56-2.236-0.12-1.478-3.883c0.758-3.763,4.237-16.106,5.378-19.864c1.142-3.758,5.727-18.728,3.893-25.556   c0,0-1.404-2.408-4.338,4.056C50.389,15.672,51.922,21.975,52.007,22.968z"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.088,30.843c-6.249,2.455-6.741,12.112-6.791,14.702   c-0.049,2.59-1.637,1.101-1.637,1.101c-2.729-3.735-0.072-9.893-0.072-9.893c3.713-8.618,8.133-11.71,9.163-12.912   c1.03-1.203,0.108-2.176-0.515-3.522c-0.623-1.346,0.286-1.731,0.286-1.731c9.193-6.054,5.842-14.452,5.842-14.452   c-2.141-4.388-5.856-2.846-5.856-2.846c-6.382,3.245-7.491,12.174-7.598,13.44c-0.107,1.266-0.086,1.107-0.264,1.514   c-0.179,0.407-0.944,0.489-0.944,0.489C1.004,19.706,0.072,28.593,0.002,31.819c-0.069,3.226,1.355,4.648,2.279,4.702   c0.924,0.055,1.01-1.44,1.01-1.44c-0.063-3.496,0.889-5.392,0.889-5.392c3.867-9.153,9.002-9.336,9.554-9.281   c0.552,0.055,0.756,0.496,0.356,0.89c-0.4,0.394-3.726,3.891-6.39,8.749c-2.664,4.858-4.117,11.038-2.166,16.156   c1.952,5.118,5.938,5.579,7.554,5.081c1.616-0.499,2.177,0.352,2.177,0.352c1.263,1.62,3.775,3.747,6.361,3.176   c2.586-0.572,4.151-4.462,3.353-5.43c-0.797-0.968-2.466,0.714-2.872,0.953c-0.406,0.238-2.075,1.89-3.173,0.937   c-1.098-0.953,0.079-1.857,0.079-1.857c6.948-4.241,7.793-12.359,7.793-12.359C27.044,28.492,21.088,30.843,21.088,30.843z    M19.109,13.262c0,0,0.594-3.869,2.857-6.258c2.263-2.389,3.331,0.483,1.703,4.08C22.043,14.681,18.346,16.376,19.109,13.262z    M22.05,39.486c-1.111,4.034-2.57,5.52-3.612,6.29c-1.042,0.77-0.972-1.943-0.972-1.943c0.966-7.221,3.61-8.482,3.61-8.482   C22.24,34.581,23.161,35.452,22.05,39.486z"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M44.895,21.979c-8.947,7.803-9.263-0.042-9.223-1.602   c0.039-1.561,1.72-2.012,5.945-5.539c4.225-3.527,5.428-7.292,5.428-7.292c1.772-8.318-4.532-6.968-4.532-6.968   c-9.636,2.833-12.459,14.907-12.459,14.907l-0.302,1.228c-0.326,1.766-1.865,1.725-3.177,2.628   c-1.312,0.903-0.761,3.674-0.738,3.773c0.024,0.099,0.434,1.002,1.441,0.198c1.008-0.804,1.822-0.448,1.807,0.001   c-0.014,0.449,0.112,3.42,0.112,3.42c1.308,9.91,9.781,5.989,10.654,5.451c0.873-0.538,1.019,0.077,1.019,0.307   c0,0.23-0.019,6.129-1.738,10.935c-1.719,4.806-2.747,7.039-4.888,7.271c-2.141,0.232-3.44-2.656-2.054-6.161   c1.385-3.505,1.128-5.105,1.128-5.105c-0.758-3.212-2.72-0.176-2.72-0.176c-2.378,3.665-1.52,9.388-1.52,9.388   c1.71,10.006,8.741,5.202,8.741,5.202c12.764-10.247,8.853-30.765,8.518-31.93C45.998,20.752,44.895,21.979,44.895,21.979z    M35.946,13.58c0,0,1.127-5.043,5.37-7.878c0,0,3.115-1.991,2.664,1.58c0,0-0.185,2.854-6.128,6.751   C37.852,14.033,35.459,16.11,35.946,13.58z"/>
        </g>
    </svg>

}