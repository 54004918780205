import {
    SidebarHistoryDtoFragment,
    SidebarKeywordHistoryDtoFragmentDoc,
    SidebarSemanticHistoryDtoFragmentDoc
} from "../../_gql/graphql";
import {unmaskFragment} from "../../_gql";
import {Link, useParams} from "react-router-dom";
import React from "react";
import {ActionIcon, Group, Menu, NavLink, rem} from "@mantine/core";
import {IconDotsVertical, IconMessage, IconSearch, IconTrash} from "@tabler/icons-react";
import {humanizeToNow} from "../../util/dates";

export const extractSidebarHistoryDto = (dto: SidebarHistoryDtoFragment) => {
    return dto.__typename === "KeywordHistoryEntryDto"
        ? unmaskFragment(SidebarKeywordHistoryDtoFragmentDoc, dto)
        : unmaskFragment(SidebarSemanticHistoryDtoFragmentDoc, dto);
}


export const LayoutHistoryViewItem = ({item, onDelete}: {
    item: SidebarHistoryDtoFragment,
    onDelete: (id: string) => void
}) => {
    const {id} = useParams();
    const history = extractSidebarHistoryDto(item);

    const handleDelete = () => {
        onDelete(history.id)
    }
    const handleXClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }
    return <Group wrap={'nowrap'}><NavLink
        leftSection={
            history.__typename === "KeywordHistoryEntryDto"
                ? <IconSearch size={16}/>
                : <IconMessage size={16}/>
        }
        rightSection={
            <Menu position={'bottom-end'}>
                <Menu.Target>
                    <ActionIcon variant="subtle" onClick={handleXClick}><IconDotsVertical size={16}/></ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        color={"red"}
                        leftSection={<IconTrash size={rem(12)}/>}
                        onClick={handleDelete}
                    >Delete chat</Menu.Item>
                </Menu.Dropdown>
            </Menu>
        }

        active={history.id === id}
        component={Link}
        key={history.id}
        label={history.query}
        to={`/history/${history.id}`}
        description={humanizeToNow(history.createdAt)}
    />

    </Group>

}
