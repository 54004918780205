import {Box, Group, Progress, Text} from "@mantine/core"
import {
    RatingDtoFragmentDoc,
    SemanticSearchResultDtoFragment,
    VoteForSearchResultDocument,
    VoteTypeEnum
} from "../../../_gql/graphql"
import {RatingView} from "../../../components/RatingView"
import {unmaskFragment} from "../../../_gql";
import React, {createRef, useEffect, useState} from "react";
import {useEgwClient} from "../../../hooks/useEgwClient";
import {ExpandableSnippet} from "./ExpandableSnippet";
import {useSearchResultListContext} from "../SemanticHistoryPage";
import {useSemanticSearchContext} from "../SemanticSearchContext";

export const SemanticSearchSnippet = ({snippet}: {
    snippet: SemanticSearchResultDtoFragment,
}) => {
    const [rating, setRating] = useState(unmaskFragment(RatingDtoFragmentDoc, snippet.rating));
    const {index} = useSearchResultListContext();
    const {queryId} = useSemanticSearchContext();
    const selected = index === snippet.order;
    const [, client] = useEgwClient();
    const boxRef = createRef<HTMLDivElement>();
    useEffect(() => {
        if (selected && boxRef.current) {
            boxRef.current.scrollIntoView({
                block: "center",
                behavior: "smooth"
            });
        }
    }, [boxRef, selected])
    const handleVote = async (vote: VoteTypeEnum | null) => {
        const voteResult = await client.mutation(VoteForSearchResultDocument, {
            vote: vote ?? null,
            order: snippet.order,
            queryId: queryId
        });
        const newRating = unmaskFragment(RatingDtoFragmentDoc, voteResult.data?.voteForSearchResult.ratingDto);
        if (newRating) {
            setRating(newRating);
        }
    }
    return <Box mt={'md'} bg={selected ? "gray.1" : undefined} ref={boxRef}>
        <Text p={0}>{snippet.order}.
            &nbsp;
            <Text component="a" c="blue" href={snippet.uri} target="_blank">{snippet.answer}</Text>
        </Text>
        <Text m={0} size="sm" mb={'xs'} c={selected ? "green" : undefined}>
            <ExpandableSnippet html={snippet.snippet} fullHtml={snippet.fullText}/>
        </Text>
        <Group justify="space-between" m={0} wrap="nowrap">
            <Text component="a" href="#" target="_blank" size="xs" c="blue">{snippet.referenceCode}</Text>
            <RatingView rating={rating} key={queryId + ":" + snippet.order} onVote={handleVote}/>
        </Group>
        <Progress mt={'xs'} size="xs" value={snippet.score * 100} striped/>
    </Box>
}