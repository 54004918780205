import React from "react";
import {useEgwClient} from "../hooks/useEgwClient";
import {Button, Container, Group, Text, Title} from "@mantine/core";
import classes from "./NotAuthorizedPage.module.css";
import {NoSidebarLayout} from "../layout/NoSidebarLayout";

export const NotAuthorizedPage = () => {
    const [auth] = useEgwClient();
    const handleLogin = async () => {
        await auth.signinRedirect();
    }
    return <NoSidebarLayout>
        <Container className={classes.root}>
            <Title className={classes.title}>You have found a secret place.</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
                In order to use ChatEGW you need to sign in with your EGW account.
            </Text>
            <Group justify="center">
                <Button variant="subtle" size="md" onClick={handleLogin}>
                    Sign in
                </Button>
            </Group>
        </Container>
    </NoSidebarLayout>
}