/* eslint-disable react-hooks/exhaustive-deps */
import {
    ChatMessageDtoFragment,
    ChatMessageType,
    ChatReplyStreamDtoFragmentDoc,
    PostReplyToChatDocument,
    RatingDtoFragment,
    RebuildReplyDocument
} from "../../_gql/graphql";
import {useEffect, useMemo, useState} from "react";
import {useMutation} from "urql";
import {unmaskFragment} from "../../_gql";
import {newUuid} from "../../util/uuid";
import {useSemanticSearchContext} from "./SemanticSearchContext";

const makeMessage = (id: string, type: ChatMessageType, text: string, rating?: RatingDtoFragment): ChatMessageDtoFragment => ({
    rating: {
        " $fragmentRefs": {
            RatingDtoFragment: rating ?? {
                __typename: "RatingDto",
                rating: 0,
                vote: null
            }
        }
    },
    id,
    type,
    text,
    createdAt: undefined,
})

export const useChatMessageHandler = () => {
    const {queryId, messages, setMessages} = useSemanticSearchContext();
    const [{data: replyData}, postReplyToChatMutation] = useMutation(PostReplyToChatDocument);
    const [{data: rebuildData}, rebuildReplyMutation] = useMutation(RebuildReplyDocument);
    const [loading, setLoading] = useState(false);
    const startLoading = () => setLoading(true);
    const finishLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 50);
    }
    const handleReply = useMemo(() => {
        return async (text: string) => {
            const userMessageId = newUuid();
            const replyMessageId = newUuid();
            const messageContent = makeMessage(userMessageId, ChatMessageType.User, text)
            const lastMessage = makeMessage(replyMessageId, ChatMessageType.System, "")
            setMessages((m) => [...m, messageContent, lastMessage]);
            startLoading();
            await postReplyToChatMutation({
                queryId: queryId,
                userMessageId: userMessageId,
                replyMessageId: replyMessageId,
                message: text
            });
            finishLoading();
        };
    }, [setMessages, postReplyToChatMutation, queryId]);

    const handleRebuildReply = useMemo(() => async (id: string) => {
        const newId = newUuid();
        startLoading();
        setMessages((m) => {
            const msgIndex = m.findIndex(m => m.id === id);
            if (msgIndex < 0) {
                return m;
            }
            const currentMessage = m[msgIndex];
            if (currentMessage.type === ChatMessageType.System) {
                return m;
            }
            const lastMessage = makeMessage(newId, ChatMessageType.System, "");
            return [...m, lastMessage]
        });

        await rebuildReplyMutation({
            messageId: newId,
            parentMessageId: id,
        })
        finishLoading();
    }, [])

    useEffect(() => {
        if (!loading) {
            return;
        }
        setMessages((m) => {
            const prevMessages = m.slice(0, m.length - 1);
            let lastMessage = m[m.length - 1];
            const fragment = unmaskFragment(ChatReplyStreamDtoFragmentDoc, replyData?.postReplyToChat?.chatReplyStreamDto);
            lastMessage = {
                ...lastMessage,
                text: (fragment?.words?.map(r => r.word).join('') ?? "")
            };
            return [...prevMessages, lastMessage]
        });
    }, [replyData]);

    useEffect(() => {
        if (!loading) {
            return;
        }
        console.log("REBUILD M");
        setMessages(m => {
            const prevMessages = m.slice(0, m.length - 1);
            let lastMessage = m[m.length - 1];
            const fragment = unmaskFragment(ChatReplyStreamDtoFragmentDoc, rebuildData?.postReplyToUserMessage?.chatReplyStreamDto);
            lastMessage = {
                ...lastMessage,
                text: (fragment?.words?.map(r => r.word).join('') ?? "")
            };
            return [...prevMessages, lastMessage]
        });
    }, [rebuildData, setMessages]);


    const onRefresh = useMemo(() => async (id: string) => {
        const msgIndex = messages.findIndex(m => m.id === id);
        if (msgIndex < 0) {
            return;
        }
        const currentMessage = messages[msgIndex];
        if (currentMessage.type === ChatMessageType.User) {
            return;
        }

        const prevMessages = messages.slice(0, msgIndex);
        const lastId = prevMessages[prevMessages.length - 1]?.id ?? newUuid();
        const lastMessage = makeMessage(id, ChatMessageType.System, "");
        prevMessages.push(lastMessage);
        setMessages(prevMessages);
        startLoading();
        await rebuildReplyMutation({
            messageId: id,
            parentMessageId: lastId,
        })
        finishLoading();
    }, [messages, setMessages, rebuildReplyMutation])

    const checkForAutoReply = useMemo(() => async () => {
        if (!messages.length) {
            return;
        }
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.type === ChatMessageType.System) {
            return;
        }
        await handleRebuildReply(lastMessage.id);
    }, [messages, handleRebuildReply]);

    return useMemo(() => ({
        messages,
        loading,
        handleRebuildReply,
        init: checkForAutoReply,
        handleReply,
    }), [
        messages,
        onRefresh,
        checkForAutoReply,
        handleRebuildReply,
        loading,
    ]);
}