export enum SearchType {
    Auto = 'auto',
    Semantic = 'semantic',
    Keyword = 'keyword'
}


export interface ChatEgwSettings {
    chatEnabled: boolean;
    defaultSearchType: SearchType;
    apiUri: string
    egwApiUri: string;
    authority: string
    clientId: string
    redirectUri: string
}

window.CHATEGW_SETTINGS = window.CHATEGW_SETTINGS || {
    chatEnabled: false,
    defaultSearchType: SearchType.Semantic,
}

export const getSiteConfig = (): ChatEgwSettings => {
    return window.CHATEGW_SETTINGS;
};
