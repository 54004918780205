import {Center, Loader} from "@mantine/core";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "urql";
import {unmaskFragment} from "../_gql";
import {
    DetailedHistoryItemFragmentDoc,
    GetSingleHistoryItemDocument,
    KeywordHistoryEntryDtoFragmentDoc,
    SemanticHistoryEntryDtoFragmentDoc
} from "../_gql/graphql";
import {NotFoundPage} from "../error-pages/NotFoundPage";
import {KeywordHistoryPage} from "./HistoryItemPage/KeywordHistoryPage";
import {notifications} from "@mantine/notifications";
import {SemanticHistoryPage} from "./HistoryItemPage/SemanticHistoryPage";

export const HistoryItemPage = () => {
    const {id} = useParams();

    const [{data, error, fetching}] = useQuery({
        query: GetSingleHistoryItemDocument,
        variables: {
            id: id
        },
        requestPolicy: "cache-and-network"
    });
    useEffect(() => {
        window.scroll(0, 0);
    }, [id])

    const rawFragment = unmaskFragment(DetailedHistoryItemFragmentDoc, data?.query)
    const fragment = rawFragment?.__typename === "KeywordHistoryEntryDto"
        ? unmaskFragment(KeywordHistoryEntryDtoFragmentDoc, rawFragment)
        : rawFragment?.__typename === "SemanticHistoryEntryDto"
            ? unmaskFragment(SemanticHistoryEntryDtoFragmentDoc, rawFragment) : null;
    let internalView = null;
    const loader = <Center mt={"xl"}>
        <Loader/>
    </Center>
    if (error) {
        notifications.show({
            title: "Error",
            message: error.message,
            color: "red",
        })
        return <NotFoundPage/>;
    }
    if (fetching || !fragment) {
        return loader;
    } else {
        internalView = fragment.__typename === "KeywordHistoryEntryDto"
            ? <KeywordHistoryPage fragment={fragment}/>
            : fragment?.__typename === "SemanticHistoryEntryDto"
                ? <SemanticHistoryPage fragment={fragment}/>
                : loader
    }
    return internalView;

}