import {BurgerProps} from "@mantine/core/lib/components/Burger/Burger";
import {Burger} from "@mantine/core";
import React, {createContext, useContext, useEffect} from "react";
import {useDisclosure} from "@mantine/hooks";
import {useLocation} from "react-router-dom";

interface IBurgerContext {
    opened: boolean,
    toggle: () => void

}

const BurgerContext = createContext<IBurgerContext>({
    opened: false,
    toggle: () => {
    }
});

export const useBurgerContext = () => {
    return useContext(BurgerContext);
}

export const BurgerProvider = ({children}: {
    children?: React.ReactNode | React.ReactNode[]
}) => {
    const {pathname} = useLocation();
    const [opened, {toggle, close}] = useDisclosure();
    const burgerContext = {
        opened,
        toggle
    };
    useEffect(() => {
        close();
    }, [pathname, close]);
    return <BurgerContext.Provider value={burgerContext}>
        {children}
    </BurgerContext.Provider>
}
export const GlobalBurger = (props: BurgerProps) => {
    const {opened, toggle} = useBurgerContext();
    return <Burger opened={opened} onClick={toggle} {...props}/>
}