import {ActionIcon, Flex, FlexProps, Input, MantineSize, rem} from "@mantine/core";
import {IconSearch} from "@tabler/icons-react";
import React, {FormEvent} from "react";

interface ActionInputProps extends FlexProps {
    value: string;
    setValue: (value: string) => void;
    onSubmit?: () => void;
    disabled?: boolean;
    title?: string;
    placeholder?: string;
    inputRef?: React.Ref<HTMLInputElement>
    icon?: React.ReactNode
    size?: MantineSize | string
}

export const ActionInput = ({
                                value,
                                setValue,
                                onSubmit,
                                disabled,
                                title,
                                placeholder,
                                size = "md",
                                inputRef,
                                icon = <IconSearch size={rem(16)}/>,
                                ...flexProps
                            }: ActionInputProps) => {
    const updatedProps: FlexProps = {
        direction: "row",
        align: "center",
        gap: "md",
        ...flexProps,
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    }
    return <Flex {...updatedProps} component={"form"} onSubmit={handleSubmit}>
        <Input
            ref={inputRef}
            style={{flexGrow: 1}}
            size={size}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            rightSectionPointerEvents="all"
            disabled={disabled}
            rightSection={
                <ActionIcon
                    size={size}
                    disabled={value.trim().length <= 3 || disabled}
                    onClick={onSubmit}
                >{icon}</ActionIcon>

            }/>
    </Flex>
}
export default ActionInput;