import React from "react";
import {Box, Divider} from "@mantine/core";
import classes from "../SemanticHistoryPage.module.scss"

export const SemanticSectionView = ({header, footer, children, scrollableRef}: {
    header?: React.ReactElement,
    footer?: React.ReactElement,
    children: React.ReactElement | React.ReactElement[],
    scrollableRef?: React.Ref<any>
}) => {
    return (
        <Box className={classes.sectionWrapper}>
            {header && <>
                {header}
                <Divider my={"sm"}/>
            </>}
            <Box className={classes.sectionContent} ref={scrollableRef}>
                {children}
            </Box>
            {footer}
        </Box>

    )
}