import {Outlet, Route, Routes} from "react-router-dom"
import {NotFoundPage} from "../error-pages/NotFoundPage"
import {HistoryItemPage} from "./HistoryItemPage"
import {Layout} from "../layout/Layout";
import IndexPage from "./IndexPage";

export const ApplicationRoutes = () => {
    return <Routes>
        <Route path='/' element={<Layout><Outlet/></Layout>}>
            <Route path="" element={<IndexPage/>}/>
            <Route path={'history/:id'} element={<HistoryItemPage/>}/>
        </Route>
        <Route path={'*'} element={<NotFoundPage/>}/>
    </Routes>
}