import {AppShell} from "@mantine/core";
import React from "react";

export const NoSidebarLayout = ({children}: {
    children?: React.ReactNode | React.ReactNode[]
}) => {
    return <AppShell
        layout="alt"
        header={{height: 60}}
        padding={{'base': '2px', 'md': 'md'}}
    >
        <AppShell.Main>
            {children}
        </AppShell.Main>
    </AppShell>
}
