import {SearchType} from "../util/config";
import {IconBrain, IconBulb, IconSearch, TablerIconsProps} from "@tabler/icons-react";
import React from "react";

interface IProps extends TablerIconsProps {
    type: SearchType;
}

export const SearchTypeIcon = ({type, ...props}: IProps) => {
    switch (type) {
        case SearchType.Auto:
            return <IconBulb {...props}/>;
        case SearchType.Keyword:
            return <IconSearch {...props}/>;
        case SearchType.Semantic:
            return <IconBrain {...props}/>;
    }
}