import ActionInput from "../../../components/ActionInput";
import {IconSend} from "@tabler/icons-react";
import React, {createRef, useEffect} from "react";
import {useChatMessageHandler} from "../useChatMessageHandler";
import {FlexProps} from "@mantine/core";

export const ChatInput = (props: FlexProps) => {
    const [value, setValue] = React.useState("");
    const {
        handleReply,
        loading,
    } = useChatMessageHandler();
    const ref = createRef<HTMLInputElement>();
    useEffect(() => {
        if (ref.current) {
            ref.current?.focus();
        }
    }, [ref]);

    const onReply = async () => {
        await handleReply(value);
        setValue("");
    }

    return <ActionInput
        inputRef={ref}
        {...props}
        onSubmit={onReply}
        value={value}
        setValue={setValue}
        disabled={loading ?? false}
        icon={<IconSend size={16}/>}/>
}