import {Box, Progress, Text} from "@mantine/core"
import {KeywordSearchResultDtoFragment} from "../../../_gql/graphql";

export default function KeywordHistorySnippet({snippet}: { snippet: KeywordSearchResultDtoFragment }) {
    return <Box mt={'lg'}>
        <Text p={0} size={'md'}>
            <Text fw={500} component={'span'}>{snippet.order}.</Text>&nbsp;<Text component="a" href="#" target="_blank"
                                                                                 c={'egw.9'}
                                                                                 size={'md'}>{snippet.referenceCode}</Text>
        </Text>
        <Text m={0} size="sm" mt={'xs'}><span dangerouslySetInnerHTML={{__html: snippet.snippet}}></span></Text>
        <Progress mt={'xs'} size={'xs'} value={snippet.score * 100} color={'egw.2'} striped/>
    </Box>
}