import {AuthProviderProps} from "react-oidc-context";
import {WebStorageStateStore} from "oidc-client-ts";

const store = new WebStorageStateStore({store: window.localStorage});
export const oidcConfig: AuthProviderProps = {
    authority: window.CHATEGW_SETTINGS.authority,
    client_id: window.CHATEGW_SETTINGS.clientId,
    redirect_uri: window.CHATEGW_SETTINGS.redirectUri,
    scope: "openid offline_access roles",
    post_logout_redirect_uri: '/',
    loadUserInfo: false,
    mergeClaims: true,
    userStore: store,
    filterProtocolClaims: false,
    onSigninCallback: async (user) => {
        if (!user) {
            return;
        }
        const userDataResponse = await fetch(oidcConfig.authority + "/connect/legacy-userinfo", {
            headers: {
                Authorization: "Bearer " + (user.access_token ?? '')
            }
        });
        const userData = await userDataResponse.json() as { id: number, username: string, is_superuser?: boolean };
        user.profile.preferred_username = userData.username;
        localStorage.setItem("username", userData.username);
        window.history.replaceState({}, document.title, window.location.pathname);
    }
};