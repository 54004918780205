import {useQuery} from "urql";
import {FolderDtoFragment, FolderDtoFragmentDoc, GetSidebarTreeDocument} from "../_gql/graphql";
import {unmaskFragment} from "../_gql";
import {useMemo} from "react";

export interface IFolder {
    value: string
    label: string
    parent?: IFolder
    children?: IFolder[]
    checked?: boolean
}

const filterFolders = (rawFolders: readonly FolderDtoFragment[]) => {
    const folderData: any = {};
    const emptyParents: string[] = [];
    let folders: IFolder[] = (rawFolders ?? []).map((f) => {
        const result = {
            value: f.id,
            label: f.title,
            parent: undefined,
            checked: false
        };
        if (f.parentId && folderData[f.parentId]) {
            folderData[f.parentId].children ??= [];
            folderData[f.parentId].children.push(result);
            result.parent = folderData[f.parentId];
        }
        if (!f.parentId) {
            emptyParents.push(f.id);
        }
        folderData[result.value] = result;
        return result;
    });
    folders = folders.filter(f => emptyParents.includes(f.value));
    if (folders.length === 1 && folders[0].children) {
        folders = folders[0].children;
    }
    return folders;
}
export const useFoldersFilter = () => {
    const [{data}] = useQuery({query: GetSidebarTreeDocument})
    const folders = useMemo(() => filterFolders(unmaskFragment(FolderDtoFragmentDoc, data?.folders) ?? []), [data]);
    return {folders}
}