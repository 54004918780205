import {SemanticSearchResultDtoFragment} from "../../../_gql/graphql";
import {useSemanticSearchContext} from "../SemanticSearchContext";
import React, {useMemo} from "react";
import {HoverCard, Text} from "@mantine/core";

export type ChatMessageLinkHandlerType = (snippet: SemanticSearchResultDtoFragment) => void;

export const ChatMessageLink = ({index, onClick}: {
    index: number,
    onClick?: ChatMessageLinkHandlerType
}) => {
    const {snippets} = useSemanticSearchContext();

    const snippet = snippets.find(r => r.order === index);
    const handleClick = useMemo(() => (e: React.MouseEvent) => {
        if (!onClick) {
            return true;
        }
        if (!snippet) {
            return;
        }
        onClick(snippet);
        e.stopPropagation();
        e.preventDefault();
    }, [onClick, snippet]);
    if (!snippet) {
        return null;
    }
    const link = snippet.uri ?
        <a href={snippet.uri} target={"_blank"} rel={"noreferrer"} onClick={handleClick}>{snippet.referenceCode}</a>
        : snippet.referenceCode;
    return <HoverCard width={400} withArrow clickOutsideEvents={['mouseup', 'touchend']} openDelay={1000}>
        <HoverCard.Target>
            <span>{link}</span>
        </HoverCard.Target>
        <HoverCard.Dropdown>
            <Text size={'sm'}>{snippet.snippet}</Text>
        </HoverCard.Dropdown>
    </HoverCard>
}