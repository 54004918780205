import {newUuid} from "../util/uuid";
import {CombinedError, useMutation} from "urql";
import {SearchType} from "../util/config";
import {NotificationData, notifications} from "@mantine/notifications";
import {useNavigate} from "react-router-dom";
import {PerformAutoSearchDocument, PerformKeywordSearchDocument, PerformSemanticSearchDocument} from "../_gql/graphql";
import {useMemo} from "react";

export const usePerformSearch = () => {
    const navigate = useNavigate();
    const [, performKeywordSearch] = useMutation(PerformKeywordSearchDocument);
    const [, performSemanticSearch] = useMutation(PerformSemanticSearchDocument);
    const [, performAutoSearch] = useMutation(PerformAutoSearchDocument);
    const executionContext = useMemo(() => ({
        additionalTypenames: ['HistoryEntry', 'SidebarHistoryDto', 'SidebarKeywordHistoryDto', 'SidebarSemanticHistoryDto']
    }), []);

    return useMemo(() => async (searchType: SearchType, query: string, folders: string[], isEgw: boolean) => {
        const id = newUuid();
        let error: CombinedError | undefined = undefined;
        const payload = {
            id,
            query,
            folders,
            isEgw: isEgw ? true : undefined
        };

        switch (searchType) {
            case SearchType.Keyword: {
                const result = await performKeywordSearch(payload, executionContext);
                error = result.error;
                break;
            }
            case SearchType.Semantic: {
                const result = await performSemanticSearch(payload, executionContext);
                error = result.error;
                break;
            }
            case SearchType.Auto: {
                const result = await performAutoSearch(payload, executionContext);
                error = result.error;
                break;
            }
        }
        if (!error) {
            navigate(`/history/${id}`)
            return;
        }
        const notification: NotificationData = {
            autoClose: 500000,
            title: "Error",
            message: error.toString(),
            color: "red",
        }
        notifications.show(notification)
    }, [navigate, performAutoSearch, performKeywordSearch, performSemanticSearch, executionContext]);
}