import {KeywordHistoryEntryDtoFragment, KeywordSearchResultDtoFragmentDoc} from "../../../_gql/graphql";
import {unmaskFragment} from "../../../_gql";
import {useSitePageTitle} from "../../../hooks/useSitePageTitle";
import React from "react";
import {ActionIcon, Container, Group, Paper, Title} from "@mantine/core";
import {SearchTypeIcon} from "../../../components/SearchTypeIcon";
import {SearchType} from "../../../util/config";
import {IconEdit} from "@tabler/icons-react";
import KeywordHistorySnippet from "./KeywordHistorySnippet";

export const KeywordHistoryPage = ({fragment}: {
    fragment: KeywordHistoryEntryDtoFragment
}) => {
    const snippets = unmaskFragment(KeywordSearchResultDtoFragmentDoc, fragment.searchResults);
    useSitePageTitle(`Search results for: ${fragment.query}`)
    return <Container>
        <Group gap={5} mt={'md'}>
            <SearchTypeIcon type={SearchType.Keyword} size={16}/>
            <Title style={{flexGrow: 1}} order={5}> Search results for: <i>{fragment.query}</i></Title>
            <ActionIcon variant={'subtle'}><IconEdit size={16}/></ActionIcon>
        </Group>
        <Paper p={{base: 5, sm: "md"}} shadow={"md"} mt={{base: 'sm', sm: 'lg'}}>
            {snippets?.map(sr => <KeywordHistorySnippet snippet={sr} key={sr.order}/>)}
        </Paper>
    </Container>

}
