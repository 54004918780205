import {cacheExchange, Client, createClient, fetchExchange} from "urql";
import {AuthContextProps, useAuth} from "react-oidc-context";

export const useEgwClient = (): [AuthContextProps, Client, string | undefined] => {
    const auth = useAuth();
    const fetchOptions = () => {
        const token = auth?.user?.access_token;
        return token ? {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        } : {};
    };
    const client = createClient({
        url: window.CHATEGW_SETTINGS.apiUri + '/graphql',
        fetchSubscriptions: true,
        fetchOptions,
        requestPolicy: "network-only",
        exchanges: [cacheExchange, fetchExchange]
    })

    return [auth, client, localStorage.getItem("username") ?? undefined];
}