import React, {useState} from "react";
import {ActionIcon} from "@mantine/core";
import {IconChevronDown, IconChevronsUp} from "@tabler/icons-react";

export const ExpandableSnippet = ({html, fullHtml}: { html: string, fullHtml: string }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(val => !val);
    const expandable = html !== fullHtml;
    const text = expanded ? fullHtml : html;
    return <><span dangerouslySetInnerHTML={{__html: text}}></span>
        {expandable &&
            <ActionIcon variant={"light"} onClick={toggleExpanded}>
                {expanded
                    ? <IconChevronsUp size={10}/>
                    : <IconChevronDown size={10}/>}
            </ActionIcon>
        }
    </>
}