import {ChatMessageLink, ChatMessageLinkHandlerType} from "./ChatMessageLink";
import React, {ReactNode, useMemo} from "react";

export const ChatMessageLinkSet = ({text, onClick}: { text: string, onClick?: ChatMessageLinkHandlerType }) => {
    const normalizedText = useMemo(() => {
        // remove optional parentheses from the beginning and the end
        return text.replace(/^\(/, "").replace(/\)$/, "").trim();
    }, [text]);
    const linkParts = useMemo(() => {
        const parts: number[] = [];

        for (let part of normalizedText.split(",").map(s => s.trim())) {
            if (part.includes("-")) {
                const [start, end] = part.split("-").map(s => parseInt(s));
                if (isNaN(start) || isNaN(end)) {
                    continue;
                }
                for (let i = start; i <= end; i++) {
                    parts.push(i);
                }
            } else {
                const parsedPart = parseInt(part);
                if (isNaN(parsedPart)) {
                    continue;
                }
                parts.push(parsedPart);
            }
        }
        // make parts unique
        parts.reverse()
        return Array.from(new Set(parts)).sort((a, b) => a - b);
    }, [normalizedText]);


    const linkPartNodes: ReactNode[] = [];
    for (let part of linkParts) {
        linkPartNodes.push(<ChatMessageLink key={part} index={part} onClick={onClick}/>);
        linkPartNodes.push(", ");
    }
    linkPartNodes.pop();
    return <>({linkPartNodes})</>
}
