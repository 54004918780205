import {useChatMessageHandler} from "../useChatMessageHandler";
import {ChatMessageView} from "./ChatMessageView";
import React, {createRef, useEffect} from "react";
import {ChatMessageLinkHandlerType} from "./ChatMessageLink";
import {useSemanticSearchContext} from "../SemanticSearchContext";

export const ChatMessageList = ({onLinkClick}: {
    onLinkClick?: ChatMessageLinkHandlerType,
}) => {
    const {queryId} = useSemanticSearchContext();
    const {
        messages,
        loading,
        init,
        handleRebuildReply,
    } = useChatMessageHandler();
    const spanRef = createRef<HTMLElement>();
    useEffect(() => {
        spanRef?.current && spanRef.current.scrollIntoView();
    }, [messages, spanRef])
    useEffect(() => {
        init();
    }, [queryId, init]);
    return <>
        {messages.map(m => <ChatMessageView
            message={m}
            key={m.id}
            loading={loading ?? false}
            onLinkClick={onLinkClick}
            onRefresh={handleRebuildReply}
        />)}
        <span ref={spanRef}/>
    </>
}