import React, {createContext, useContext, useMemo, useState} from "react";
import {ActionIcon, Box, Checkbox, Group, Stack} from "@mantine/core";
import {IconChevronDown, IconChevronRight} from "@tabler/icons-react";

export interface IFilterable {
    value: string
    label: React.ReactNode | string
    parent?: IFilterable
    children?: IFilterable[]
}

interface ITreeFilterProps {
    data: IFilterable[]
    selected: string[]
    setSelected: (selected: string[]) => void
}

interface ITreeFilterContext {
    selected: string[]
    expanded: string[]

    expand(id: string): void

    collapse(id: string): void

    select(id: string): void

    deselect(id: string): void
}

const TreeFilterContext = createContext<ITreeFilterContext>({} as ITreeFilterContext);

const getCheckboxState = (item: IFilterable, selected: string[]): boolean | null => {
    if (selected.includes(item.value)) {
        return true;
    }
    if (item.children && item.children.length) {
        const childrenState = item.children
            .map(r => getCheckboxState(r, selected));
        if (childrenState.every(r => r === true)) {
            return true;
        }
        if (childrenState.every(r => r === false)) {
            return false;
        }
        return null;
    }
    return false;
}

const TreeItemDisplay = ({item}: { item: IFilterable }) => {
    const treeContext = useContext(TreeFilterContext);
    const checkState = getCheckboxState(item, treeContext.selected);
    const isChecked = checkState === true;
    const isIndeterminate = checkState === null;
    const isExpanded = treeContext.expanded.includes(item.value);
    const hasChildren = item.children && item.children.length;

    function handleSelectClick() {
        if (isChecked) {
            treeContext.deselect(item.value);
        } else {
            treeContext.select(item.value);
        }
    }

    const handleExpandClick = () => {
        if (isExpanded) {
            treeContext.collapse(item.value);
        } else {
            treeContext.expand(item.value);
        }
    }
    const expandButton = <ActionIcon variant={'transparent'} color={'gray'} size={'sm'} onClick={handleExpandClick}>
        {isExpanded ? <IconChevronDown/> : <IconChevronRight/>}
    </ActionIcon>

    const selector = <Group align={'center'} m={0} p={0} gap={0}>
        <Checkbox checked={isChecked} indeterminate={isIndeterminate} onChange={handleSelectClick} mr={'xs'} size={'xs'}
                  color={'egw.3'}/>
        <Box onClick={hasChildren ? handleExpandClick : handleSelectClick}
             style={{cursor: "pointer"}}>{item.label}</Box>
        {hasChildren && expandButton}
    </Group>

    return hasChildren && isExpanded ?
        <Stack m={0} p={0} gap={0}>
            {selector}
            {item.children && item.children.length && isExpanded &&
                <Box ml={'xs'}>
                    {item.children!.map(c => <TreeItemDisplay item={c} key={c.value}/>)}
                </Box>}
        </Stack>
        : selector;

}


const findItem = (data: IFilterable[], id: string): IFilterable | null => {
    for (const item of data) {
        if (item.value === id) {
            return item;
        }
        if (item.children && item.children.length) {
            const result = findItem(item.children, id);
            if (result) {
                return result;
            }
        }
    }
    return null;
}
const getChildrenIdsRecursive = (item: IFilterable): string[] => {
    const children = item.children || [];
    return [item.value, ...children.flatMap(getChildrenIdsRecursive)];
}


export const TreeFilter = ({
                               data, selected, setSelected
                           }: ITreeFilterProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);

    const handleSelectClick = useMemo(() => (id: string, value: boolean) => {
        const item = findItem(data, id);
        if (item === null) {
            return;
        }
        const childrenIds = getChildrenIdsRecursive(item);
        const parents: string[] = [];
        let parent: IFilterable | undefined = item?.parent;
        while (parent) {
            parents.push(parent.value);
            parent = parent.parent;
        }
        let newSelected = value
            ? [...selected, ...childrenIds]
            : selected.filter(r => !childrenIds.includes(r));
        setSelected(Array.from(new Set(newSelected.filter(r => !parents.includes(r)))));
    }, [data, selected, setSelected]);

    const context = useMemo<ITreeFilterContext>(() => ({
        selected,
        expanded,
        expand: (id: string) => setExpanded([...expanded, id]),
        collapse: (id: string) => setExpanded(expanded.filter(r => r !== id)),
        select: (id: string) => handleSelectClick(id, true),
        deselect: (id: string) => handleSelectClick(id, false), // setSelected(selected.filter(r => r !== id))
    }), [selected, expanded, setExpanded, handleSelectClick]);

    return <TreeFilterContext.Provider value={context}>{data.map(r => <TreeItemDisplay item={r}
                                                                                       key={r.value}/>)}</TreeFilterContext.Provider>
}