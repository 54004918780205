import React from "react";
import {ChatMessageDtoFragment, SemanticSearchResultDtoFragment} from "../../_gql/graphql";

const SemanticSearchContext = React.createContext<{
    query: string,
    queryId: string,
    snippets: readonly SemanticSearchResultDtoFragment[],
    messages: readonly ChatMessageDtoFragment[],
    setMessages: React.Dispatch<React.SetStateAction<ChatMessageDtoFragment[]>>,
    wide: boolean
}>({
    query: "",
    queryId: "",
    snippets: [],
    messages: [],
    setMessages: (m) => m,
    wide: false
});

export const SemanticSearchContextProvider = SemanticSearchContext.Provider;
export const useSemanticSearchContext = () => React.useContext(SemanticSearchContext);