import {useEgwClient} from "../../hooks/useEgwClient";
import {AppShell, Divider, Group, NavLink, ScrollArea} from "@mantine/core";
import {GlobalBurger} from "../../components/GlobalBurger";
import {IconEdit, IconLogout} from "@tabler/icons-react";
import {NavLink as RouterLink} from "react-router-dom";
import {UserAvatar} from "../../components/UserAvatar";
import React from "react";
import {EgwTitle} from "./EgwTitle";
import {LayoutHistoryView} from "./LayoutHistoryView";

export const LayoutNavBar = () => {
    const [auth, , userName] = useEgwClient();
    return <>
        <AppShell.Section hiddenFrom={"sm"}>
            <Group justify={"space-between"} px={'xs'}>
                <EgwTitle/>
                <GlobalBurger size={"sm"}/>
            </Group>
            <Divider/>
        </AppShell.Section>
        <AppShell.Section>
            <NavLink rightSection={<IconEdit/>} component={RouterLink} to={'/'} label={"New search"} pt={'xs'}/>
        </AppShell.Section>
        <AppShell.Section grow component={ScrollArea}>
            <LayoutHistoryView/>
        </AppShell.Section>
        <AppShell.Section pb={'xs'}>
            <NavLink label={"Sign out " + userName} leftSection={<UserAvatar size={24}/>}
                     onClick={() => auth.removeUser()}
                     rightSection={<IconLogout/>}/>
        </AppShell.Section>
    </>

}