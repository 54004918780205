import {Box, Container, Divider, Group, LoadingOverlay, Paper, Radio, Stack, Title} from "@mantine/core";
import {useLocalStorage} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import ActionInput from "../components/ActionInput";
import {SearchTypeSelector} from "../components/SearchTypeSelector";
import {useFoldersFilter} from "../hooks/useFolders";
import {TreeFilter} from "../components/TreeFilter";
import {usePerformSearch} from "../hooks/usePerformSearch";
import {HelpTextComponent} from "./IndexPage/HelpTextComponent";
import {useSitePageTitle} from "../hooks/useSitePageTitle";


type ScopeFilters = 'all' | 'egw' | 'filter';

function SearchType({value, onChange}: { value: ScopeFilters, onChange?: (value: ScopeFilters) => void }) {
    function handleSearchScopeChange(e: string) {
        onChange && onChange(e as ScopeFilters);
    }

    return <Radio.Group
        name="favoriteFramework"
        label="Search scope"
        value={value}
        onChange={handleSearchScopeChange}>
        <Group mt={"md"}>
            <Radio value="all" label="Everywhere"/>
            <Radio value="egw" label="EGW Writings Only"/>
            <Radio value="filter" label="Filter by collection"/>
        </Group>
    </Radio.Group>;
}

const IndexPage = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState("");
    const [selected, setSelected] = React.useState<string[]>([]);
    const [searchScope, setSearchScope] = useState<ScopeFilters>('all');
    const [searchType, setSearchType] = useLocalStorage({
        key: "default-search-type",
        defaultValue: window.CHATEGW_SETTINGS.defaultSearchType
    });
    const inputRef = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);
    const performSearch = usePerformSearch();
    const handleSearch = async () => {
        let egwOnly: boolean = false;
        let selectedFolders: string[] = [];
        switch (searchScope) {
            case 'all':
                break;
            case 'egw':
                egwOnly = true;
                break;
            case 'filter':
                selectedFolders = selected;
                break;
        }
        setLoading(true);
        await performSearch(searchType, value, selectedFolders, egwOnly);
    }
    const {folders} = useFoldersFilter()
    useSitePageTitle();
    return <Container pos="relative" px={{base: 5, 'sm': 'sm'}} style={{display: "flex", flexDirection: "column"}}>
        <LoadingOverlay visible={loading}/>
        <Paper p={{base: 5, sm: "md"}} shadow={"md"} mt={{base: 'sm', sm: 'lg'}}>
            <Stack>
                <Title order={5}>Search for an answer</Title>
                <Divider/>
                <ActionInput
                    inputRef={inputRef}
                    size={"md"}
                    style={{flexGrow: 1}}
                    value={value}
                    setValue={setValue}
                    placeholder="Type in your search query&hellip;"
                    onSubmit={handleSearch}
                    gap={"xs"}/>

                <SearchTypeSelector fullWidth value={searchType} onChange={setSearchType}/>
                <SearchType value={searchScope} onChange={setSearchScope}/>
                {searchScope === 'filter' &&
                    <Box ml={'md'}> <TreeFilter data={folders} selected={selected} setSelected={setSelected}/></Box>}
                <Box mt={'md'}>
                    <HelpTextComponent/>
                </Box>
            </Stack>
        </Paper>
    </Container>
}

export default IndexPage;