import {useMutation, useQuery} from "urql";
import {DeleteQueryByIdDocument, GetSidebarHistoryDocument, SidebarHistoryDtoFragmentDoc} from "../../_gql/graphql";
import {unmaskFragment} from "../../_gql";
import {Center, Loader} from "@mantine/core";
import React from "react";
import {extractSidebarHistoryDto, LayoutHistoryViewItem} from "./LayoutHistoryViewItem";

export const LayoutHistoryView = () => {
    const [{data, fetching}, refetch] = useQuery({query: GetSidebarHistoryDocument});
    const [, executeMutation] = useMutation(DeleteQueryByIdDocument);
    const items = unmaskFragment(SidebarHistoryDtoFragmentDoc, data?.queries) ?? [];
    if (fetching || !items) {
        return <Center mt={"xl"}><Loader/></Center>
    }
    const handleDelete = (id: string) => {
        if (!items) {
            return;
        }
        executeMutation({id})
            .then(() => {
                refetch({requestPolicy: 'network-only'})
            });
    }
    return <>{items.map((row) => {
        return <LayoutHistoryViewItem item={row} key={extractSidebarHistoryDto(row).id} onDelete={handleDelete}/>
    })}</>
}