import {
    ChatMessageDtoFragment,
    ChatMessageType,
    RatingDtoFragmentDoc,
    VoteForChatMessageDocument,
    VoteTypeEnum
} from "../../../_gql/graphql";
import {ActionIcon, Group, Paper, Text} from "@mantine/core";
import {IconRefresh} from "@tabler/icons-react";
import {unmaskFragment} from "../../../_gql";
import React, {useState} from "react";
import {useEgwClient} from "../../../hooks/useEgwClient";
import {RatingView} from "../../../components/RatingView";
import {ChatMessageText} from "./ChatMessageText";
import {ChatMessageLinkHandlerType} from "./ChatMessageLink";


export const ChatMessageView = ({message, loading, onRefresh, onLinkClick}: {
    message: ChatMessageDtoFragment,
    loading: boolean,
    onRefresh?: (id: string) => void,
    onLinkClick?: ChatMessageLinkHandlerType,
    scrollableRef?: React.Ref<HTMLElement>
}) => {
    const paperProps = {
        mb: "md",
        withBorder: true,
        p: "sm"
    }
    const blockMargin = "20%";
    const [, client] = useEgwClient();

    const [rating, setRating] = useState(unmaskFragment(RatingDtoFragmentDoc, message.rating));
    const handleVote = async (vote: VoteTypeEnum | null) => {
        const voteResult = await client.mutation(VoteForChatMessageDocument, {
            messageId: message.id,
            vote: vote ?? null,
        });
        const newRating = unmaskFragment(RatingDtoFragmentDoc, voteResult.data?.voteForChatMessage.ratingDto);
        if (newRating) {
            setRating(newRating);
        }
    }
    const handleRefresh = () => {
        onRefresh && onRefresh(message.id)
    }

    if (message.type === ChatMessageType.User) {
        return <Paper {...paperProps} ml={blockMargin} bg={"blue.2"}>
            <Text size={"sm"}>{message.text}</Text>
        </Paper>
    }

    return <Group wrap={"nowrap"} justify={"space-between"} key={message.id}>
        <Paper {...paperProps} >
            <ChatMessageText text={message.text} onClick={onLinkClick}/>
            <Group mt={'md'} gap={5}>
                <RatingView disabled={loading} rating={rating} onVote={handleVote} key={message.id}/>
                <ActionIcon disabled={loading} onClick={handleRefresh} size={"xs"}
                            variant="subtle"><IconRefresh/></ActionIcon>
            </Group>
        </Paper>
    </Group>
}
